@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Jetbrain";
  src: url("./assets/fonts/JetBrainsMono-Medium.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Vazir";
  src: url("./assets/fonts/Vazirmatn-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glacer";
  src: url("./assets/fonts/Glacer.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IranSans";
  src: url("./assets/fonts/IRANSansXRegular.ttf");
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Jetbrain', 'Vazir', 'IranSans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5e6673;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5b6277;
}


button {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  color: #fff;
  background-color: #6200ee;
  padding: 1rem 2rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  outline: 0;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  /* black with 30% opacity */
  cursor: pointer;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

a {
  text-decoration: none;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #161a1e !important;
}

.custom-calendar .rmdp-month-picker {
  background-color: #161a1e !important;
}

.custom-calendar .rmdp-year-picker {
  background-color: #161a1e !important;
}

.rmdp-container {
  width: 100%;
}

.rmdp-analog-clock {
  border: none !important;
  outline: 5px solid #09589e;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

@layer base {
  img {
    @apply inline-block;
  }
}